import React from 'react';
import './Publications.css';

const Publications = () => (
  <>
    {/* <div className="publication-banner-image" /> */}
    <div className="publications">
      <h2>Selected Publications</h2>
      <div className="pub-content">
        <a className="publication-link" href="https://bestnewpoets.org">“Partition Story”</a>
        {' '}
        in
        {' '}
        <i>Best New Poets, University of Virginia Press</i>
        {' '}
        - 2024.
      </div>
      <div className="pub-content">
        {/* <a className="publication-link" href="https://bestnewpoets.org"> */}
        “Big Brown Boys”
        {/* </a> */}
        {' '}
        in
        {' '}
        <i>NELLE Magazine</i>
        {' '}
        - 2023.
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://theoffingmag.com/interviews/qa-with-jacqui-germain-author-of-bittering-the-wound/">“Q&A with Jacqui Germain: Author of Bittering the Wound”</a>
        {' '}
        in
        {' '}
        <i>The Offing</i>
        {' '}
        - 2023.
      </div>
      <div className="pub-content">
        {/* <a className="publication-link" href="https://bestnewpoets.org"> */}
        “Elegy for Living Mothers” and “Collected Vision”
        {/* </a> */}
        {' '}
        in
        {' '}
        <i>Epiphany Magazine</i>
        {' '}
        - 2023.
      </div>
      <div className="pub-content">
        {/* <a className="publication-link" href="https://bestnewpoets.org"> */}
        “Self-Portrait with The Taj Mahal”
        {/* </a> */}
        {' '}
        in
        {' '}
        <i>Provincetown Arts Magazine</i>
        {' '}
        - 2023.
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://theoffingmag.com/interviews/qa-with-natasha-rao-author-of-latitude/">“Q&A with Natasha Rao, Author of Latitude”</a>
        {' '}
        in
        {' '}
        <i>The Offing</i>
        {' '}
        - 2023.
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://theoffingmag.com/interviews/qa-with-joshua-nguyen-author-of-come-clean/">“Mitski, Marie Kondo, and Coming Clean: Q&A with Joshua Nguyen”</a>
        {' '}
        in
        {' '}
        <i>The Offing</i>
        {' '}
        - 2022.
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://thepunchmagazine.com/the-byword/poetry/the-poetry-issue-2022-ghazal-after-babri-masjid-and-other-poems">“Ghazal After Babri Masjid” and other poems </a>
        {' '}
        {' '}
        in
        {' '}
        <i>Punch Magazine - Special Poetry Issue</i>
        {' '}
        - 2022.
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://www.quarterlywest.com/issue-104-awasthi">“The Mother Wound”</a>
        {' '}
        {' '}
        in
        {' '}
        <i>Quarterly West</i>
        {' '}
        - 2021.
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://ndreview.nd.edu/archived-issues/ndr-52/"> “Lotus-fishing at J.K. Temple” </a>
        {' '}
        {' '}
        in
        {' '}
        <i>Notre Dame Review</i>
        {' '}
        (Issue 52) - 2021.
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://www.amarujala.com/columns/blog/national-bird-peacock-colour-and-importance"> “Attractive Birds of India, “National Bird Peacock” </a>
        {' '}
        {' '}
        in
        {' '}
        <i>Amar Ujala </i>
        {' '}
        (Hindi newspaper) - 2021.
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://livewire.thewire.in/gender-and-sexuality/how-the-indian-school-system-reinforces-gender-norms/">“How The Indian School System Reinforces Gender Norms”</a>
        {' '}
        in
        <i> The Wire </i>
        2020.
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://livewire.thewire.in/politics/women-against-caa-nrc-breaking-the-patriarchy-from-within%E2%80%A8/">“Women Against CAA, NRC: Breaking the Patriarchy From Within”</a>
        {' '}
        in
        {' '}
        <i>The Wire</i>
        {' '}
        2020.
      </div>
      <div className="pub-content">
        Three poems in
        {' '}
        <a className="publication-link" href="https://www.amazon.in/Ninety-Seven-Poems-Terribly-Tiny-Tales/dp/0143441175">“Ninety Seven Poems”</a>
        {' '}
        anthology published by
        <i> Penguin India </i>
        2018.
      </div>
      <div className="pub-content">
        <a className="publication-link" href="https://www.filmcompanion.in/features/bollywood-features/psycho-shower-scene-netflix-78-52-alfred-hitchcock/">
          “Five Things We Learnt About Psycho’s Shower Scene”
        </a>
        {' '}
        in
        <i> Film Companion </i>
        2018.
      </div>
      <div className="pub-content">
        Three micro-fiction tales in the
        {' '}
        <a className="publication-link" href="https://www.amazon.com/Terribly-Tiny-Tales-Vol-I/dp/0143441167">“Terribly Tiny Tales”</a>
        {' '}
        anthology published by
        <i> Penguin India </i>
        2017.
      </div>
    </div>
  </>
);

export default Publications;
