import React from 'react';
import './Awards.css';

const Awards = () => (
  <>
    <div className="awards-banner-image" />
    <div className="publications">
      <h2>Scholarships and Awards</h2>
      <div className="pub-content">
        Semi-finalist, Lexi Rudnitsky First Book Prize 2024
      </div>
      <div className="pub-content">
        Writer-in-Residence, Ragdale Foundation 2024
      </div>
      <div className="pub-content">
        The Corporation of Yaddo, Residency in Writing 2024
      </div>
      <div className="pub-content">
        Best New Poets, University of Virginia Press 2024
      </div>
      <div className="pub-content">
        Hedgebrook Writer-in-Residence Winter-Spring Season 2024
      </div>
      <div className="pub-content">
        Long-listed for the Disquiet Literary Prize 2023
      </div>
      <div className="pub-content">
        National Endowment for the Arts Distinguished Fellowship, The Hambidge Center 2023
      </div>
      <div className="pub-content">
        Scholarship for Community of Writers, Lake Tahoe 2022
      </div>
      <div className="pub-content">
        Long-listed for the Emerging Poet Prize, Palette Poetry, 2021
      </div>
      <div className="pub-content">
        Graduate Teaching Assistantship, McNeese State University, 2018 - 2021
      </div>
      <div className="pub-content">
        Scholarship for Bread Loaf Writer’s Workshop with Paisley Rekdal, 2021
      </div>
      <div className="pub-content">
        Scholarship for Documentary Poetics with Kimberly Alidio by Kundiman, 2021
      </div>
      <div className="pub-content">
        Louisiana Division of the Arts Individual Artist Grant, Art and Humanities Council, 2021
      </div>
      <div className="pub-content">
        Joyce Scantlebury Second Prize for Poetry, McNeese State University, 2021
      </div>
      <div className="pub-content">
        Lynne & Richard Reid Scholarship, McNeese State University, 2021
      </div>
      <div className="pub-content">
        Fellowship for Marginalized Writers, Sundress Academy for the Arts(SAFTA), 2021
      </div>
      <div className="pub-content">
        John Wood Award in Poetry, McNeese State University, 2019
      </div>
      <div className="pub-content">
        Award for excellence in Academics, Wilson College, 2017
      </div>
      <div className="pub-content">
        Young Critics Lab shortlist, Mumbai Film Festival (MAMI), 2016
      </div>
    </div>
  </>
);

export default Awards;
