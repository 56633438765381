/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [subject, setSubject] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState('');
  const [showSubmitMessage, setShowSubmitMessage] = React.useState(false);
  const sendEmail = () => {
    if (!name) {
      setError('Please fill in your Name');
    } else if (!email) {
      setError('Please fill in your Email');
    } else if (!subject) {
      setError('Please fill in the Subject');
    } else if (!message) {
      setError('Please fill in your Message');
    } else {
      const templateParams = {
        name,
        subject,
        email,
        message,
      };

      emailjs.send('gmail', 'template_x5wd3f8', templateParams, 'user_SjJLJrXam9zwWyFHLbdCA')
        .then(() => {
          // console.log('SUCCESS!', response.status, response.text);
          setShowSubmitMessage(true);
          setName('');
          setEmail('');
          setMessage('');
          setSubject('');
        }, () => {
          // console.log('FAILED...', err);
        });
    }
  };
  return (
    <>
      <div className="contact-banner-image" />
      <div className="publications">
        <h2>Contact</h2>
        <form className="mail-form">
          <div>Name*</div>
          <input type="text" value={name} onChange={(e) => { setName(e.target.value); setError(''); setShowSubmitMessage(false); }} className="mail-input" />
          <div>Email*</div>
          <input type="text" value={email} onChange={(e) => { setEmail(e.target.value); setError(''); setShowSubmitMessage(false); }} className="mail-input" />
          <div>Subject*</div>
          <input type="text" value={subject} onChange={(e) => { setSubject(e.target.value); setError(''); setShowSubmitMessage(false); }} className="mail-input" />
          <div>Message*</div>
          <textarea value={message} onChange={(e) => { setMessage(e.target.value); setError(''); setShowSubmitMessage(false); }} className="mail-input-area" />
          <div className="error-message">{error}</div>
          {showSubmitMessage ? <div className="submit-message">Thanks for reaching out. Gauri will get in touch with you shortly.</div> : null}
          <button type="button" className="submit-button" onClick={sendEmail}>Send</button>
        </form>
      </div>
    </>
  );
};

export default Contact;
