import React from 'react';
import './Events.css';

const Events = () => (
  <>
    <div className="events-banner-image" />
    <div className="publications">
      <h2>Readings</h2>
      <div className="pub-content">
        Presenter, Poetry Film Festival of Los Angeles, CA, 2023
      </div>
      <div className="pub-content">
        Reader, Poetry Panel at C.D. Wright Conference, University of Central
        Arkansas, Conway, AR, 2022
      </div>
      <div className="pub-content">
        Presenter, Generation Women, Joe’s Pub, Public Theater, New York, NY, 2022
      </div>
      <div className="pub-content">
        Paper presentation at The Society for Comparative Literature and Arts, Austin, Texas, 2021
      </div>
      <div className="pub-content">
        “Politics of Protest” Panel at South Central Modern Language Association, Houston, 2021
      </div>
      <div className="pub-content">
        Tin House Summer Workshop with Diana Khoi Ngyuen, 2021
      </div>
      <div className="pub-content">
        Action Optimism, Virtual talk and interview at Oxford Climate Society
        , University of Oxford, 2021
      </div>
      <div className="pub-content">
        Paper presentation at Global Souths Conference, University of Louisiana, Lafayette, 2021
      </div>
      <div className="pub-content">
        Reader, Hero Foundation Virtual Poetry Showcase for Charity, 2021
      </div>
      <div className="pub-content">
        Rewriting Gender in School System with Humans of Safe Places, 2020
      </div>
      <div className="pub-content">
        Reader, Lockdown Poetry Mic with Danish Hussain, 2020
      </div>
      <div className="pub-content">
        Poetry Panel Presentation at South Central Modern Language Association, Little Rock, 2019
      </div>
      <div className="pub-content">
        Reader, Dr. Grey’s Free Range Reading Series, Stellar Beans, Lake Charles, 2019
      </div>
      <div className="pub-content">
        Member of Association of Writers and Writing Program, 2018-2021
      </div>
      <div className="pub-content">
        Veganism and Sustainability with Chef Rakhee Vaswani, 2018
      </div>
      <div className="pub-content">
        Reader, Canvas Kavita #2, The Poetry Club, TARQ Art Gallery, Mumbai, 2016
      </div>
    </div>
  </>
);

export default Events;
